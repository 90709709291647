import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

// Post Answers
export const postAnswers = createAsyncThunk('fasttrack/postAnswers', async ({ userId, responses }) => {
    try {
        let data = {
            "source": "kelasfullstack",
            "user_id": userId,
            "responses": JSON.stringify(responses)
        };

        if (!userToken) return;
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/user-reference`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: userToken
            }
        });
        return response.data;

    } catch (error) {
        throw new Error(error);
    };
});

const fasttrackSlice = createSlice({
    name: 'fasttrack',
    initialState: {
        loadingFasttrack: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(postAnswers.pending, (state, action) => {
                state.loadingFasttrack = true;
            })
            .addCase(postAnswers.fulfilled, (state, action) => {
                state.loadingFasttrack = false;
            })
            .addCase(postAnswers.rejected, (state, action) => {
                state.loadingFasttrack = false;
            });
    },
});

export default fasttrackSlice.reducer;
