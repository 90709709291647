import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../components/global/Layout/Layout';
import CardCourseRoadmap from '../../components/learn/roadmap/CardCourseRoadmap/CardCourseRoadmap';
import { getRoadmap } from '../../features/roadmaps/roadmapSlice';
import { questions, recommendations } from '../../data/fast-track';
import { postAnswers } from '../../features/fasttrack/fasttrackSlice';
import { userId } from '../../utils/config';

const KFFastTrack = () => {
    const [greeting, setGreeting] = useState("");
    const [tryQuiz, setTryQuiz] = useState("");
    const [answers, setAnswers] = useState(Array(questions.length).fill(null));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [result, setResult] = useState(null);

    const { myUser } = useSelector(state => state.user);
    const { roadmap, loadingRoadmap } = useSelector(state => state.roadmap);
    const { loadingFasttrack } = useSelector(state => state.fastTrack);

    const dispatch = useDispatch();

    // Greeting
    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            setGreeting("Selamat Pagi");
        } else if (currentHour < 15) {
            setGreeting("Selamat Siang");
        } else if (currentHour < 18) {
            setGreeting("Selamat Sore");
        } else {
            setGreeting("Selamat Malam");
        }
    }, []);

    // Get KelasFullstack Courses
    useEffect(() => {
        dispatch(getRoadmap('menjadi-fullstack-web-developer'));
    }, [dispatch]);

    useEffect(() => {
        const myFastTrack = localStorage.getItem('myFastTrack');
        if (myFastTrack) {
            setResult(JSON.parse(myFastTrack));
            setTryQuiz("yes");
        };
    }, []);

    const handleAnswer = (index) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = questions[currentQuestion].options[index];
        setAnswers(newAnswers);

        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            dispatch(postAnswers({ userId, responses: newAnswers })).then((response) => {
                if (response.payload.status !== 'success') {
                    alert("Terjadi kesalahan, silahkan coba kemabali");
                    window.location.reload();
                    return;
                };
                determineRecommendation(newAnswers);
            });
        };
    };

    const determineRecommendation = (answers) => {
        let bestMatch = null;
        let highestScore = 0;

        recommendations.forEach((rec) => {
            let score = rec.factors.filter((factor) => answers.includes(factor)).length;
            if (score > highestScore) {
                highestScore = score;
                bestMatch = rec;
            }
        });

        setResult(bestMatch);
        localStorage.setItem('myFastTrack', JSON.stringify(bestMatch));
    };

    const resetQuiz = () => {
        setTryQuiz("");
        setAnswers(new Array(questions.length).fill(null));
        setCurrentQuestion(0);
        setResult(null);
        localStorage.removeItem('myFastTrack');
    };

    return (
        <>
            <Helmet>
                <title>KF Fast Track</title>
            </Helmet>
            <Layout>
                <section className="section py-5 vh-min-100">
                    <div className="container-fluid">
                        <div className="d-flex gap-3 align-items-center">
                            <span
                                className="bg-white rounded-circle p-3 d-inline-block"
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden'
                                }}
                            >
                                <img
                                    src="https://image.web.id/images/robotba7a5f03bb2cf662.png"
                                    alt="Robot"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                />
                            </span>
                            <div>
                                {result && (
                                    <button className="btn btn-outline-primary" onClick={resetQuiz}>
                                        Ulangi Pertanyaan?
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="row my-3 w-100">
                            <div className="col-12">
                                <div className="card border-0 rounded-3">
                                    <div className="card-body p-4">
                                        {!tryQuiz && (
                                            <>
                                                <h5 className="fw-bold">{greeting}, {myUser?.name}!</h5>
                                                <p>KelasFullstack adalah produk belajar yang dirancang dengan serius dan tidak pelit materi (Ribuan jam belajar). Memiliki banyak mentor yang siap menjawab pertanyaan, jika kamu kesulitan dalam belajar.</p>
                                                <p>Kami siap menemani proses belajar kakak sampai menjadi Web Developer Expert dari 0.  Perlu diketahui, untuk belajar materi kami yang begitu lengkap dibutuhkan waktu 6 bulan sampai 1 tahun, sampai mencapai tujuan akhir.</p>
                                                <p>Dalam produk ini, kami juga menyediakan kurikulum “Fast Track (Beta)” yang disesuaikan dengan kondisi kakak, untuk belajar lebih cepat lagi.</p>
                                                <p>Mau mencoba?</p>
                                                <div className="d-grid d-md-flex gap-2">
                                                    <button type="button" className="btn btn-secondary" onClick={() => setTryQuiz("no")}>Nanti saja</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => setTryQuiz("yes")}>Coba Fast Track</button>
                                                </div>
                                            </>
                                        )}
                                        {tryQuiz === "yes" && (
                                            <>
                                                {loadingFasttrack ? (
                                                    <div className="d-flex flex-column align-items-start gap-3">
                                                        <div className="spinner-border text-primary" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                        <p className="mb-0">Baik, izin mengolah semua jawaban kakak, mohon ditunggu sebentar...</p>
                                                    </div>
                                                ) : result ? (
                                                    <>
                                                        <h5 className="fw-bold h4">{result.title}</h5>
                                                        <p className="mb-4">Kami pikir kakak lebih cocok mengambil fast track ini. Berikut panduan belajar dan durasi:</p>

                                                        <div className="alert alert-primary my-3" role="alert">
                                                            Preferensi ini sudah kami bantu simpan di sistem dan tidak akan berubah. Jika ingin mengubah, silahkan klik tombol "Ulangi Pertanyaan".
                                                        </div>

                                                        {result?.months.map((month, index) => (
                                                            <div className="mb-5" key={index}>
                                                                <h6 className="fw-bold h5"><FontAwesomeIcon icon={faCalendarCheck} className="text-primary me-2" /> {month.title}</h6>
                                                                <p className="mb-3">{month.description}</p>
                                                                <div className="row g-4">
                                                                    {!loadingRoadmap &&
                                                                        roadmap?.courses?.filter((course) =>
                                                                            month?.courseTitles?.some((courseTitle) => course?.course_title?.trim().toLowerCase() === courseTitle?.trim().toLowerCase())
                                                                        )?.map((filteredCourse) => (
                                                                            <div className="col-md-6 col-lg-4 col-xl-3" key={filteredCourse.id}>
                                                                                <Link className="link" to={`/learn/courses/detail/${filteredCourse.slug}`}>
                                                                                    <CardCourseRoadmap
                                                                                        thumbnail={filteredCourse.cover}
                                                                                        title={filteredCourse.course_title}
                                                                                        totalDuration={filteredCourse.total_time}
                                                                                        totalModule={filteredCourse.total_module}
                                                                                        status={filteredCourse.completed}
                                                                                        labels={filteredCourse.label}
                                                                                    />
                                                                                </Link>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <h5 className="mb-3">{questions[currentQuestion].question}</h5>
                                                        <div className="d-grid d-md-flex gap-2">
                                                            {questions[currentQuestion].options.map((option, index) => (
                                                                <button
                                                                    key={index}
                                                                    className="btn btn-light rounded-pill border border-2"
                                                                    onClick={() => handleAnswer(index)}
                                                                >
                                                                    {option}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {tryQuiz === "no" && (
                                            <>
                                                <p className="mb-0">Terima kasih atas jawabannya. Silahkan melanjutkan belajar sesuai dengan keinginan kakak di materi KelasFullstack yang tersedia!</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default KFFastTrack;