export const questions = [
    {
        question: "Berapa kisaran gaji yang kakak harapkan setelah menguasai keterampilan ini?",
        options: ["4 Juta sampai 6 Juta", "6 Juta sampai 15 Juta"],
    },
    {
        question: "Seberapa lama kakak bersedia untuk belajar dan menguasai keterampilan ini?",
        options: ["1 sampai 6 bulan", "6 bulan sampai 1 tahun", "1 tahun sampai 5 tahun"],
    },
    {
        question: "Bahasa pemrograman mana yang lebih kakak minati untuk dipelajari?",
        options: ["HTML, CSS, & Javascript", "PHP & SQL", "Semua"],
    },
    {
        question: "Lebih suka mana antara Javascript dan PHP?",
        options: ["Javascript", "PHP"],
    },
    {
        question: "Siapa diri kakak dalam konteks pekerjaan saat ini?",
        options: ["Pelajar", "Mahasiswa", "Swasta"],
    },
    {
        question: "Apa jenis pekerjaan yang paling kakak nikmati?",
        options: ["Visual", "Data", "Keduanya"],
    },
];

export const recommendations = [
    {
        title: "Frontend Developer",
        factors: ["4 Juta sampai 6 Juta", "1 sampai 6 bulan", "HTML, CSS, & Javascript", "Swasta", "Visual"],
        months: [
            {
                id: 1,
                title: "Bulan Pertama",
                description: "Understand the Context :",
                courseTitles: [
                    "Panduan Member KelasFullstack.id",
                    "Mengenal Pemrograman Komputer",
                    "Algoritma dan Pemrograman Dasar",
                    "Mahir menggunakan Text Editor buat Pemula",
                    "Belajar Menggunakan Terminal atau CMD untuk Development",
                    "Belajar Git Pemula"
                ]
            },
            {
                id: 2,
                title: "Bulan Kedua",
                description: "The Basic of Web :",
                courseTitles: [
                    "Developer & Desain: Membangun Dasar UI/UX yang Solid",
                    "Belajar Dasar HTML",
                    "Belajar Dasar CSS",
                    "Belajar CSS Framework Bootstrap 5"
                ]
            },
            {
                id: 3,
                title: "Bulan Ketiga",
                description: "The Slicing Days Challenge :",
                courseTitles: [
                    "Membuat Landing Page Website dengan Bootstrap 4",
                    "Membuat Halaman Website Portofolio Menggunakan Tailwind CSS",
                    "Membuat Website E-Wallet dengan Tailwind - Slicing",
                    "Membuat Website Crowd Funding dengan Tailwind - Slicing"
                ]
            },
            {
                id: 4,
                title: "Bulan Keempat",
                description: "The Javascript Days :",
                courseTitles: [
                    "Belajar JavaScript",
                    "Belajar JavaScript Asynchronous",
                    "Belajar JavaScript DOM",
                    "Belajar AJAX dan Web API"
                ]
            },
            {
                id: 5,
                title: "Bulan Kelima",
                description: "Become React Hero :",
                courseTitles: [
                    "Belajar React.js Fundamental",
                    "React.js 101 - Membuat dan Menggunakan Komponen dengan Berbagai Studi Kasus",
                    "React.js State - Mengelola Perubahan Tampilan dengan Data bersama Event Handler",
                    "React.js State Management - Panduan Menggunakan State yg Baik",
                    "Belajar React.js dengan Membuat Aplikasi Split Bill"
                ]
            },
            {
                id: 6,
                title: "Bulan Keenam",
                description: "Become Frontend Expert :",
                courseTitles: [
                    "React.js - Belajar Lebih Dalam Tentang Component dan Jenisnya",
                    "React.js - Bagaimana React.js Bekerja di Balik Layar",
                    "React.js - Belajar Hook Effect dan Data Fetching"
                ]
            },
            {
                id: 6,
                title: "Bulan Ketujuh",
                description: "Complete Your Skill :",
                courseTitles: [
                    "Panduan Karir dan Branding",
                    "Belajar Hosting Lengkap",
                    "Puluhan BONUS Kelas Live! Persembahan KelasFullstack"
                ]
            }
        ],
    },
    {
        title: "Backend PHP",
        factors: ["4 Juta sampai 6 Juta", "6 bulan sampai 1 tahun", "PHP & SQL", "PHP", "Swasta", "Data"],
        months: [
            {
                id: 1,
                title: "Bulan Pertama",
                description: "Understand the Context :",
                courseTitles: [
                    "Panduan Member KelasFullstack.id",
                    "Mengenal Pemrograman Komputer",
                    "Algoritma dan Pemrograman Dasar",
                    "Mahir menggunakan Text Editor buat Pemula",
                    "Belajar Menggunakan Terminal atau CMD untuk Development",
                    "Belajar Git Pemula"
                ]
            },
            {
                id: 2,
                title: "Bulan Kedua",
                description: "The Basic of Web & JS Intro :",
                courseTitles: [
                    "Developer & Desain: Membangun Dasar UI/UX yang Solid",
                    "Belajar Dasar HTML",
                    "Belajar Dasar CSS",
                    "Belajar CSS Framework Bootstrap 5",
                    "Belajar JavaScript"
                ]
            },
            {
                id: 3,
                title: "Bulan Ketiga",
                description: "The Engine of Web :",
                courseTitles: [
                    "Belajar Pemrograman PHP",
                    "Pengenalan Database Menggunakan MySQL",
                    "PHP 7 dan MySQL: Panduan CRUD Lengkap untuk Pemula (Versi Lama)",
                    "PHP 8 dan MySQL: Panduan CRUD Lengkap untuk Pemula (Terbaru)",
                    "Konsep Dasar Pemrograman Berorientasi Objek di PHP"
                ]
            },
            {
                id: 4,
                title: "Bulan Keempat",
                description: "Explore Industrial Based Framework :",
                courseTitles: [
                    "Pengembangan Web Fullstack dengan Laravel 11",
                    "Menguasai Framework CodeIgniter 3",
                    "Membangun RESTful API dengan Laravel Studi Kasus Blog"
                ]
            },
            {
                id: 5,
                title: "Bulan Kelima",
                description: "Complete Your Skill :",
                courseTitles: [
                    "Panduan Karir dan Branding",
                    "Belajar Hosting Lengkap",
                    "Puluhan BONUS Kelas Live! Persembahan KelasFullstack"
                ]
            }
        ]
    },
    {
        title: "Backend Javascript",
        factors: ["4 Juta sampai 6 Juta", "6 bulan sampai 1 tahun", "HTML, CSS, & Javascript", "Javascript", "Swasta", "Data"],
        months: [
            {
                id: 1,
                title: "Bulan Pertama",
                description: "Understand the Context :",
                courseTitles: [
                    "Panduan Member KelasFullstack.id",
                    "Mengenal Pemrograman Komputer",
                    "Algoritma dan Pemrograman Dasar",
                    "Mahir menggunakan Text Editor buat Pemula",
                    "Belajar Menggunakan Terminal atau CMD untuk Development",
                    "Belajar Git Pemula"
                ]
            },
            {
                id: 2,
                title: "Bulan Kedua",
                description: "The Basic of Web & JS Intro :",
                courseTitles: [
                    "Developer & Desain: Membangun Dasar UI/UX yang Solid",
                    "Belajar Dasar HTML",
                    "Belajar Dasar CSS",
                    "Belajar CSS Framework Bootstrap 5",
                    "Belajar JavaScript"
                ]
            },
            {
                id: 3,
                title: "Bulan Ketiga",
                description: "The Javascript Days :",
                courseTitles: [
                    "Belajar JavaScript",
                    "Belajar JavaScript Asynchronous",
                    "Belajar JavaScript DOM",
                    "Belajar AJAX dan Web API"
                ]
            },
            {
                id: 4,
                title: "Bulan Keempat",
                description: "The Backend Engine :",
                courseTitles: [
                    "Belajar Dasar Node.js dan NPM",
                    "Belajar Dasar Express.js",
                    "Belajar MongoDB",
                    "Belajar Membangun RESTful dengan Adonis.js",
                    "Belajar RESTful dengan Express.js"
                ]
            },
            {
                id: 5,
                title: "Bulan Kelima",
                description: "Complete Your Skill :",
                courseTitles: [
                    "Panduan Karir dan Branding",
                    "Belajar Hosting Lengkap",
                    "Puluhan BONUS Kelas Live! Persembahan KelasFullstack"
                ]
            }
        ]
    },
    {
        title: "Fullstack PHP",
        factors: ["6 Juta sampai 15 Juta", "6 bulan sampai 1 tahun", "PHP & SQL", "PHP", "Mahasiswa", "Keduanya"],
        months: [
            {
                id: 1,
                title: "Bulan Pertama",
                description: "Understand the Context :",
                courseTitles: [
                    "Panduan Member KelasFullstack.id",
                    "Mengenal Pemrograman komputer",
                    "Algoritma dan Pemrograman Dasar",
                    "Mahir menggunakan Text Editor buat Pemula",
                    "Belajar Menggunakan Terminal atau CMD untuk Development",
                    "Belajar Git Pemula"
                ]
            },
            {
                id: 2,
                title: "Bulan Kedua",
                description: "The Basic of Web & JS Intro :",
                courseTitles: [
                    "Developer & Desain: Membangun Dasar UI/UX yang Solid",
                    "Belajar Dasar HTML",
                    "Belajar Dasar CSS",
                    "Belajar CSS Framework Bootstrap 5",
                    "Belajar JavaScript"
                ]
            },
            {
                id: 3,
                title: "Bulan Ketiga",
                description: "The Engine of Web :",
                courseTitles: [
                    "Belajar Pemrograman PHP",
                    "Pengenalan Database Menggunakan MySQL",
                    "PHP 7 dan MySQL: Panduan CRUD Lengkap untuk Pemula (Versi Lama)",
                    "PHP 8 dan MySQL: Panduan CRUD Lengkap untuk Pemula (Terbaru)",
                    "Konsep Dasar Pemrograman Berorientasi Objek di PHP"
                ]
            },
            {
                id: 4,
                title: "Bulan Keempat",
                description: "Explore Industrial Based Framework :",
                courseTitles: [
                    "Pengembangan Web Fullstack dengan Laravel 11",
                    "Menguasai Framework CodeIgniter 3"
                ]
            },
            {
                id: 5,
                title: "Bulan Kelima",
                description: "Explore Berbagai Studi Kasus :",
                courseTitles: [
                    "Membangun Website Toko Online Berbasis CodeIgniter",
                    "Membangun Aplikasi Cek Ongkir Menggunakan Laravel",
                    "Membuat Toko Online dengan Laravel LiveWire dan Payment Gateway"
                ]
            },
            {
                id: 6,
                title: "Bulan Keenam",
                description: "Complete Your Skill :",
                courseTitles: [
                    "Panduan Karir dan Branding",
                    "Belajar Hosting Lengkap",
                    "Puluhan BONUS Kelas Live! Persembahan KelasFullstack"
                ]
            }
        ]
    },
    {
        title: "Fullstack Javascript",
        factors: ["6 Juta sampai 15 Juta", "6 bulan sampai 1 tahun", "HTML, CSS, & Javascript", "Javascript", "Mahasiswa", "Keduanya"],
        months: [
            {
                id: 1,
                title: "Bulan Pertama",
                description: "Understand the Context :",
                courseTitles: [
                    "Panduan Member KelasFullstack.id",
                    "Mengenal Pemrograman komputer",
                    "Algoritma dan Pemrograman Dasar",
                    "Mahir menggunakan Text Editor buat Pemula",
                    "Belajar Menggunakan Terminal atau CMD untuk Development",
                    "Belajar Git Pemula"
                ]
            },
            {
                id: 2,
                title: "Bulan Kedua",
                description: "The Basic of Web & JS Intro :",
                courseTitles: [
                    "Developer & Desain: Membangun Dasar UI/UX yang Solid",
                    "Belajar Dasar HTML",
                    "Belajar Dasar CSS",
                    "Belajar CSS Framework Bootstrap 5",
                    "Belajar JavaScript"
                ]
            },
            {
                id: 3,
                title: "Bulan Ketiga",
                description: "The Javascript Days :",
                courseTitles: [
                    "Belajar JavaScript",
                    "Belajar JavaScript Asynchronous",
                    "Belajar JavaScript DOM",
                    "Belajar AJAX dan Web API"
                ]
            },
            {
                id: 4,
                title: "Bulan Keempat",
                description: "The Backend Engine :",
                courseTitles: [
                    "Belajar Dasar Node.js dan NPM",
                    "Belajar Dasar Express.js",
                    "Belajar MongoDB",
                    "Belajar Membangun RESTful dengan Adonis.js",
                    "Belajar RESTful dengan Express.js"
                ]
            },
            {
                id: 5,
                title: "Bulan Kelima",
                description: "Explore Berbagai Studi Kasus :",
                courseTitles: [
                    "Belajar Membuat Halaman Web Dinamis dengan Express.js dan EJS",
                    "Membangun Website Modern dengan NextJS, Studi Kasus Headless CMS",
                    "Menguasai Nuxt.js dan Tailwindcss dengan 2 Project",
                    "Belajar Menguasai Nest Js, Framework Javascript Populer"
                ]
            },
            {
                id: 6,
                title: "Bulan Keenam",
                description: "Complete Your Skill :",
                courseTitles: [
                    "Panduan Karir dan Branding",
                    "Belajar Hosting Lengkap",
                    "Puluhan BONUS Kelas Live! Persembahan KelasFullstack"
                ]
            }
        ]
    },
];